import { getData } from '../modules/fetch';

const getReviewDetails = (id: string, callback, errorCallback) => {
  getData(`/reviews/${id}`)
    .then((data) => {
      if (typeof callback === 'function') {
        callback(data);
      }
    })
    .catch((err) => {
      console.error(err);
      if (typeof errorCallback === 'function') {
        errorCallback(err);
      }
    });
};

export default getReviewDetails;
