import styled from 'styled-components';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import Pagination from '@mui/material/Pagination';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { DateRange } from 'react-date-range';

export const StyledTableContainer = styled(TableContainer)`
  width: 100%;
`;
export const Container = styled.div`
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;

  .head-cell {
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0.5px;
    background: #f2f2f2;
  }

  td {
    font-size: 1rem;
    letter-spacing: 0.5px;
  }
`;
export const ActionsContainer = styled(TableCell)`
  display: flex;
`;
export const Title = styled.h4`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: #050d42;
`;

export const StyledPagination = styled(Pagination)`
  ul {
    flex-wrap: nowrap;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  button {
    min-width: 48%;
    margin-bottom: 30px;
  }
`;

export const StyledTextField = styled(TextField)`
  width: 95%;
  margin: 0 auto;
`;

export const StyledButton = styled(Button)`
  min-width: 150px !important;
`;

export const StyledCancelButton = styled(Button)`
  position: absolute !important;
  top: 390px;
  left: 0;
  width: 150px;
  min-width: 150px !important;
`;

export const StyledDetailsItem = styled.div`
  margin-bottom: 10px;
  text-align: left;
  font-size: 16px;
`;
export const DateContainer = styled.div`
  position: relative;
  height: 53px;
`;

export const DateInput = styled.div`
  height: 53px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  background-color: #fff;
  border-radius: 4px;
  padding: 8px 12px;
  color: rgba(0, 0, 0, 0.7);
  font-size: 1rem;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    font-size: 16px;
    position: relative;
    top: -1px;
  }
`;

export const StyledCalendarTodayIcon = styled(CalendarTodayIcon)`
  margin-right: 5px;
`;

export const StyledDateRange = styled(DateRange)`
  position: absolute;
  left: 0;
  top: 55px;
  border-radius: 10px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  z-index: 100;
`;
