import { getData } from '../modules/fetch';
import { parseBackendDate } from '../modules/parseBackendDate';

const getReviews = (
  {
    page = 1,
    perPage = 10,
    provider = '',
    rating = '',
    query = '',
    startDate = '',
    endDate = '',
    commented = '',
    reply = '',
  } = {},
  callback,
) => {
  const searchParams = new URLSearchParams({
    provider,
    rating,
    query,
    startDate,
    endDate,
    commented,
    reply,
  });

  const url = `/reviews/listings/${page},${perPage}?${searchParams.toString()}`;

  getData(url)
    .then((data) => {
      if (typeof callback === 'function') {
        if (data?.data && Array.isArray(data.data)) {
          const parsedData = data.data.map((item) => {
            const dt = parseBackendDate(item.createdAt?.date, item.createdAt?.timezone);
            return {
              ...item,
              timestamp: dt.valueOf(),
            };
          });

          callback({
            data: parsedData,
            meta: data.meta || {},
          });
        } else {
          callback({
            data: [],
            meta: data?.meta || {},
          });
        }
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

export default getReviews;
