import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import {
  ButtonsContainer,
  Container,
  DateContainer,
  DateInput,
  StyledButton,
  StyledCalendarTodayIcon,
  StyledCancelButton,
  StyledDateRange,
  StyledDetailsItem,
  StyledPagination,
  StyledTableContainer,
  StyledTextField,
  Title,
} from './styles';
import { ErrorBoundaryFallback, ErrorBoundaryHandler } from '../../components/ErrorBoundary';
import Header from '../../components/Header';
import { ErrorBoundary } from 'react-error-boundary';
import Nav from '../../components/Nav';
import { Body, PageContainer } from '../../components/Layout';
import TablePagination from '@mui/material/TablePagination';
import { Box, FormControl, Select, TableSortLabel, TextField } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { logMessage } from '../../logger';
import getReviews from '../../api/getReviews';
import Button from '@mui/material/Button';
import TableHead from '@mui/material/TableHead';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Popup from '../../components/Popup';
import { FormattedMessage } from 'react-intl';
import postReply from '../../api/postReply';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import getReviewDetails from '../../api/getReviewDetails';
import { format } from 'date-fns';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;
  const handlePagination = (event, value) => {
    logMessage(event);
    onPageChange(event, value - 1);
  };

  return (
    <Box sx={{ display: 'flex', ml: 2.5 }}>
      <StyledPagination
        count={Math.ceil(count / rowsPerPage)}
        siblingCount={0}
        defaultPage={page + 1}
        onChange={handlePagination}
      />
    </Box>
  );
}

function formatMilliseconds(ms: number): string {
  const date = new Date(ms);
  const pad = (num: number): string => num.toString().padStart(2, '0');

  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const year = pad(date.getFullYear() % 100);
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());

  return `${month}/${day}/${year} ${hours}:${minutes}`;
}

const getDayRangeInSeconds = (startDate: Date, endDate: Date): { start: string; end: string } => {
  const start = new Date(startDate);
  start.setHours(0, 0, 0, 0);
  const end = new Date(endDate);
  end.setHours(23, 59, 59, 999);
  const startSeconds = Math.floor(start.getTime() / 1000);
  const endSeconds = Math.floor(end.getTime() / 1000);
  return { start: String(startSeconds), end: String(endSeconds) };
};

const providerLogos: Record<string, string> = {
  shopee: '/pics/shopee_small.png',
  lazada: '/pics/lazada_small.png',
  line: '/pics/line_small.png',
  shopify: '/pics/shopify_small.png',
};

const Reviews = () => {
  const [search, setSearch] = useState('');
  const [platform, setPlatform] = useState('');
  const [rating, setRating] = useState<string[]>(['all']);
  const [commented, setCommented] = useState('');
  const [reply, setReply] = useState('');
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showReplyPopup, setShowReplyPopup] = useState(false);
  const [showReviewDetailsPopup, setShowReviewDetailsPopup] = useState(false);
  const [replyText, setReplyText] = useState('');
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tableSourceData, setTableSourceData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedSkuId, setSelectedSkuId] = useState('');
  const [reviewDetails, setReviewDetails] = useState<any>(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [showCalendar, setShowCalendar] = useState(false);
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const columns = [
    {
      id: 'store',
      label: 'Store Name',
      render: (row) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={providerLogos[row.store?.provider]}
              alt={row.store?.provider}
              style={{ width: 20, height: 20, marginRight: 5 }}
            />
            {row.store?.name} ({row.store?.countryCode})
          </div>
        );
      },
      sortable: true,
    },
    {
      id: 'sku',
      label: 'SKU',
      render: (row) => row.product?.name ?? '',
      sortable: true,
    },
    {
      id: 'orderId',
      label: 'Order #',
      render: (row) => row.orderId ?? '',
      sortable: true,
    },
    {
      id: 'overallRating',
      label: 'Rating',
      render: (row) => row.overallRating ?? '',
      sortable: true,
    },
    {
      id: 'buyerUsername',
      label: 'Customer Name',
      render: (row) => row.buyerUsername ?? '',
      sortable: true,
    },
    {
      id: 'comment',
      label: 'Review Text',
      render: (row) => row.comment ?? '',
      sortable: true,
    },
    {
      id: 'timestamp',
      label: 'Timestamp',
      render: (row: any) => {
        return row.timestamp ? formatMilliseconds(row.timestamp) : '';
      },
      sortable: true,
    },
    {
      id: 'reply',
      label: 'Reply Text',
      render: (row) => (
        <span
          style={{ color: 'dodgerblue', textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => openReviewDetails(row)}
        >
          {row.reply?.content ?? ''}
        </span>
      ),
      sortable: true,
    },
    {
      id: 'actions',
      label: 'Actions',
      render: (row) => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={!!row.reply?.content}
          onClick={() => openReplyPopup(row._id.$oid)}
        >
          <FormattedMessage id="reviews.reply" defaultMessage="Reply" />
        </Button>
      ),
      sortable: false,
    },
  ];
  const openReplyPopup = (id: string) => {
    receiveReviewDetails(id);
    setSelectedSkuId(id);
    setReplyText('');
    setShowReplyPopup(true);
  };

  const openReviewDetails = (row: any) => {
    receiveReviewDetails(row._id.$oid);
    setShowReviewDetailsPopup(true);
  };

  const fetchReviews = (currentPage: number = page, currentRowsPerPage: number = rowsPerPage) => {
    getReviews(
      {
        page: currentPage + 1,
        perPage: currentRowsPerPage,
        provider: platform,
        rating: rating.includes('all') ? '' : rating.join(','),
        query: search,
        startDate: startDate,
        endDate: endDate,
        commented: commented,
        reply: reply,
      },
      (response: any) => {
        if (response?.data && response?.meta?.pagination) {
          setTableSourceData(response.data);
          setTotalCount(response.meta.pagination.total);
        }
      },
    );
  };

  const handleChangePage = (event: any, newPage: number) => {
    logMessage(event);
    setPage(newPage);
    fetchReviews(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    logMessage(event);
    const newRows = parseInt(event.target.value, 10);
    setRowsPerPage(newRows);
    setPage(0);
    fetchReviews(0, newRows);
  };

  const handleSort = (columnId) => {
    if (sortBy === columnId) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(columnId);
      setSortOrder('asc');
    }
  };

  const sortedData = useMemo(() => {
    if (!sortBy) return tableSourceData;
    return [...tableSourceData].sort((a, b) => {
      const valA = a[sortBy];
      const valB = b[sortBy];
      if (valA < valB) return sortOrder === 'asc' ? -1 : 1;
      if (valA > valB) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });
  }, [tableSourceData, sortBy, sortOrder]);
  const handleApplyFilters = () => {
    setPage(0);
    fetchReviews(0, rowsPerPage);
  };

  const handleReplySubmit = (e) => {
    e.preventDefault();
    setShowReplyPopup(false);
    postReply(
      selectedSkuId,
      {
        comment: replyText,
      },
      (response: any) => {
        if (response) {
          fetchReviews(page, rowsPerPage);
        }
      },
      (error) => {
        console.log('postReply error', error);
        setShowErrorPopup(true);
      },
    );
    setSelectedSkuId('');
  };

  const receiveReviewDetails = (id: string) => {
    getReviewDetails(
      id,
      (response: any) => {
        setReviewDetails(response);
      },
      (error) => {
        console.log('getReviewDetails error', error);
        setShowErrorPopup(true);
      },
    );
  };

  const handleDateRangeChange = (range) => {
    setDate([range.selection]);
    const { start, end } = getDayRangeInSeconds(range.selection.startDate, range.selection.endDate);
    setStartDate(start);
    setEndDate(end);
  };

  const handleCancel = () => {
    setShowCalendar(false);
  };

  useEffect(() => {
    const { start, end } = getDayRangeInSeconds(new Date(), new Date());
    setStartDate(start);
    setEndDate(end);
    getReviews(
      {
        page: 1,
        perPage: rowsPerPage,
        provider: platform,
        rating: rating.includes('all') ? '' : rating.join(','),
        query: search,
        startDate: String(start),
        endDate: String(end),
        commented: commented,
        reply: reply,
      },
      (response: any) => {
        if (response?.data && response?.meta?.pagination) {
          setTableSourceData(response.data);
          setTotalCount(response.meta.pagination.total);
        }
      },
    );
  }, []);

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={ErrorBoundaryHandler}>
        <Header />
      </ErrorBoundary>
      <PageContainer>
        <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={ErrorBoundaryHandler}>
          <Nav currentStep="dashboard" subStep="chat-statistics" />
        </ErrorBoundary>
        <Body>
          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={ErrorBoundaryHandler}>
            <Container>
              <Title>
                <FormattedMessage id="reviews.page_title" defaultMessage="Reviews" />
              </Title>
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  mb: 2,
                }}
              >
                <TextField
                  label={
                    <FormattedMessage
                      id="reviews.search_label"
                      defaultMessage="Search (order #, product, username...)"
                    />
                  }
                  variant="outlined"
                  size="medium"
                  sx={{ minWidth: 270 }}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />

                <FormControl size="medium" sx={{ minWidth: 120 }}>
                  <InputLabel>
                    <FormattedMessage id="reviews.platform_label" defaultMessage="Platform" />
                  </InputLabel>
                  <Select
                    label={
                      <FormattedMessage id="reviews.platform_label" defaultMessage="Platform" />
                    }
                    value={platform}
                    onChange={(e) => setPlatform(e.target.value)}
                  >
                    <MenuItem value="">All Platforms</MenuItem>
                    <MenuItem value="shopee">Shopee</MenuItem>
                    <MenuItem value="lazada">Lazada</MenuItem>
                  </Select>
                </FormControl>

                <FormControl size="medium" sx={{ minWidth: 120 }}>
                  <InputLabel>
                    <FormattedMessage id="reviews.reply_label" defaultMessage="Reply" />
                  </InputLabel>
                  <Select
                    label={<FormattedMessage id="reviews.reply_label" defaultMessage="Reply" />}
                    value={reply}
                    onChange={(e) => setReply(e.target.value)}
                  >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="0">Not replied</MenuItem>
                    <MenuItem value="1">Only replied</MenuItem>
                  </Select>
                </FormControl>

                <FormControl size="medium" sx={{ minWidth: 120 }}>
                  <InputLabel>
                    <FormattedMessage id="reviews.comment_label" defaultMessage="Comment" />
                  </InputLabel>
                  <Select
                    label={<FormattedMessage id="reviews.comment_label" defaultMessage="Comment" />}
                    value={commented}
                    onChange={(e) => setCommented(e.target.value)}
                  >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="0">Empty comment</MenuItem>
                    <MenuItem value="1">Only commented</MenuItem>
                  </Select>
                </FormControl>

                <FormControl size="medium" sx={{ minWidth: 120 }}>
                  <InputLabel>
                    <FormattedMessage id="reviews.rating_label" defaultMessage="Rating" />
                  </InputLabel>
                  <Select
                    label={<FormattedMessage id="reviews.rating_label" defaultMessage="Rating" />}
                    multiple={true}
                    value={rating}
                    onChange={(e) => {
                      const value = e.target.value as string[];

                      if (value.length > 1 && value[value.length - 1] === 'all') {
                        setRating(['all']);
                      } else if (value.length > 1 && value[0] === 'all') {
                        setRating(value.filter((v) => v !== 'all'));
                      } else {
                        setRating(value);
                      }
                    }}
                    renderValue={(selected) => {
                      if (selected.includes('all')) return 'All';
                      return selected.join(', ');
                    }}
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="1">★</MenuItem>
                    <MenuItem value="2">★ ★</MenuItem>
                    <MenuItem value="3">★ ★ ★</MenuItem>
                    <MenuItem value="4">★ ★ ★ ★</MenuItem>
                    <MenuItem value="5">★ ★ ★ ★ ★</MenuItem>
                  </Select>
                </FormControl>
                <DateContainer>
                  <DateInput onClick={() => setShowCalendar(!showCalendar)}>
                    <StyledCalendarTodayIcon />
                    {format(date[0].startDate, 'MM/dd/yy')} - {format(date[0].endDate, 'MM/dd/yy')}
                  </DateInput>
                  {showCalendar && (
                    <>
                      <StyledDateRange
                        editableDateInputs={true}
                        onChange={handleDateRangeChange}
                        moveRangeOnFirstSelection={false}
                        ranges={date}
                        showDateDisplay={false}
                      />
                      <StyledCancelButton
                        variant="contained"
                        color="primary"
                        size="medium"
                        onClick={handleCancel}
                      >
                        <FormattedMessage
                          id="auto-reply.popup.button.cancel"
                          defaultMessage="Cancel"
                        />
                      </StyledCancelButton>
                    </>
                  )}
                </DateContainer>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={handleApplyFilters}
                >
                  <FormattedMessage id="reviews.apply_filters" defaultMessage="Apply Filters" />
                </Button>
              </Box>
              <StyledTableContainer>
                <Table aria-label="reviews table">
                  <TableHead>
                    <TableRow>
                      {columns.map((col) => (
                        <TableCell key={col.id}>
                          {col.sortable ? (
                            <TableSortLabel
                              active={sortBy === col.id}
                              direction={sortOrder}
                              onClick={() => handleSort(col.id)}
                            >
                              {col.label}
                            </TableSortLabel>
                          ) : (
                            col.label
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedData.map((row: any, index: number) => (
                      <TableRow key={`row-${index}`}>
                        {columns.map((col) => (
                          <TableCell key={col.id} align="center">
                            {col.render(row)}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        colSpan={columns.length}
                        count={totalCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: {
                            'aria-label': 'rows per page',
                          },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </StyledTableContainer>
              {showReplyPopup && (
                <Popup
                  isPreviewTicket={false}
                  setIsPreviewTicket={() => {}}
                  handleClose={() => setShowReplyPopup(false)}
                >
                  <>
                    <Title>
                      <FormattedMessage id="reviews.title" defaultMessage="Write your reply" />
                    </Title>
                    <StyledDetailsItem>
                      <strong>
                        <FormattedMessage
                          id="reviews.store_details"
                          defaultMessage="Store / Platform / Country: "
                        />
                      </strong>
                      {reviewDetails?.data?.store?.name} / {reviewDetails?.data?.store?.provider} /{' '}
                      {reviewDetails?.data?.store?.countryCode}
                    </StyledDetailsItem>
                    <StyledDetailsItem>
                      <strong>
                        <FormattedMessage id="reviews.comment_details" defaultMessage="Comment: " />
                      </strong>{' '}
                      {reviewDetails?.data?.comment}
                    </StyledDetailsItem>
                    <StyledDetailsItem>
                      <strong>
                        <FormattedMessage id="reviews.rating_details" defaultMessage="Rating: " />
                      </strong>{' '}
                      {reviewDetails?.data?.overallRating} ★
                    </StyledDetailsItem>
                    <StyledDetailsItem>
                      <strong>
                        <FormattedMessage
                          id="reviews.product_name_details"
                          defaultMessage="Product name: "
                        />
                      </strong>{' '}
                      {reviewDetails?.data?.product?.name}
                    </StyledDetailsItem>
                    <StyledDetailsItem>
                      <strong>
                        <FormattedMessage id="reviews.order_details" defaultMessage="Order: " />
                      </strong>{' '}
                      {reviewDetails?.data?.orderId}
                    </StyledDetailsItem>
                    <StyledDetailsItem>
                      <strong>
                        <FormattedMessage id="reviews.images_details" defaultMessage="Images: " />
                      </strong>
                      {reviewDetails?.data?.images?.length ? (
                        <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                          {reviewDetails.data.images.map((link: string, idx: number) => (
                            <a key={idx} href={link} target="_blank" rel="noreferrer">
                              <img
                                src={link}
                                alt={`Review Image ${idx + 1}`}
                                style={{
                                  width: '200px',
                                  height: '200px',
                                  objectFit: 'cover',
                                  borderRadius: '5px',
                                  cursor: 'pointer',
                                }}
                              />
                            </a>
                          ))}
                        </div>
                      ) : (
                        <span> None</span>
                      )}
                    </StyledDetailsItem>
                    <StyledDetailsItem>
                      <strong>
                        <FormattedMessage id="reviews.videos_details" defaultMessage="Videos: " />
                      </strong>
                      {reviewDetails?.data?.videos?.length ? (
                        reviewDetails?.data.videos.map((link: string, idx: number) => (
                          <div key={idx}>
                            <a href={link} target="_blank" rel="noreferrer">
                              {link}
                            </a>
                          </div>
                        ))
                      ) : (
                        <span> None</span>
                      )}
                    </StyledDetailsItem>
                    <form autoComplete="off" onSubmit={handleReplySubmit}>
                      <StyledTextField
                        label="Reply text"
                        variant="outlined"
                        multiline
                        rows={5}
                        value={replyText}
                        onChange={(e) => {
                          if (e.target.value.length <= 500) {
                            setReplyText(e.target.value);
                          }
                        }}
                      />
                      <ButtonsContainer>
                        <StyledButton variant="contained" color="primary" type="submit">
                          <FormattedMessage id="reviews.send_button" defaultMessage="Send" />
                        </StyledButton>
                      </ButtonsContainer>
                    </form>
                  </>
                </Popup>
              )}
              {showReviewDetailsPopup && (
                <Popup
                  isPreviewTicket={false}
                  setIsPreviewTicket={() => {}}
                  handleClose={() => setShowReviewDetailsPopup(false)}
                >
                  <>
                    <Title>
                      <FormattedMessage
                        id="reviews.details_title"
                        defaultMessage="Review details"
                      />
                    </Title>
                    <StyledDetailsItem>
                      <strong>
                        <FormattedMessage
                          id="reviews.store_details"
                          defaultMessage="Store / Platform / Country: "
                        />
                      </strong>
                      {reviewDetails?.data?.store?.name} / {reviewDetails?.data?.store?.provider} /{' '}
                      {reviewDetails?.data?.store?.countryCode}
                    </StyledDetailsItem>
                    <StyledDetailsItem>
                      <strong>
                        <FormattedMessage id="reviews.comment_details" defaultMessage="Comment: " />
                      </strong>{' '}
                      {reviewDetails?.data?.comment}
                    </StyledDetailsItem>
                    <StyledDetailsItem>
                      <strong>
                        <FormattedMessage id="reviews.rating_details" defaultMessage="Rating: " />
                      </strong>{' '}
                      {reviewDetails?.data?.overallRating} ★
                    </StyledDetailsItem>
                    <StyledDetailsItem>
                      <strong>
                        <FormattedMessage id="reviews.reply_details" defaultMessage="Reply: " />
                      </strong>{' '}
                      {reviewDetails?.data?.reply?.content}
                    </StyledDetailsItem>
                    <StyledDetailsItem>
                      <strong>
                        <FormattedMessage id="reviews.images_details" defaultMessage="Images: " />
                      </strong>
                      {reviewDetails?.data?.images?.length ? (
                        <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                          {reviewDetails.data.images.map((link: string, idx: number) => (
                            <a key={idx} href={link} target="_blank" rel="noreferrer">
                              <img
                                src={link}
                                alt={`Review Image ${idx + 1}`}
                                style={{
                                  width: '200px',
                                  height: '200px',
                                  objectFit: 'cover',
                                  borderRadius: '5px',
                                  cursor: 'pointer',
                                }}
                              />
                            </a>
                          ))}
                        </div>
                      ) : (
                        <span> None</span>
                      )}
                    </StyledDetailsItem>
                    <StyledDetailsItem>
                      <strong>
                        <FormattedMessage id="reviews.videos_details" defaultMessage="Videos: " />
                      </strong>
                      {reviewDetails?.data?.videos?.length ? (
                        reviewDetails?.data.videos.map((link: string, idx: number) => (
                          <div key={idx}>
                            <a href={link} target="_blank" rel="noreferrer">
                              {link}
                            </a>
                          </div>
                        ))
                      ) : (
                        <span> None</span>
                      )}
                    </StyledDetailsItem>
                  </>
                </Popup>
              )}
              {showErrorPopup && (
                <Popup
                  isPreviewTicket={false}
                  setIsPreviewTicket={() => {}}
                  handleClose={() => setShowErrorPopup(false)}
                >
                  <>
                    <Title>
                      <FormattedMessage
                        id="storeDetails.tabs.tab.title.error"
                        defaultMessage="Error: "
                      />
                    </Title>
                    <div>
                      <strong>
                        <FormattedMessage
                          id="reviews.error_text"
                          defaultMessage="Service error, please try again later"
                        />
                      </strong>
                    </div>
                  </>
                </Popup>
              )}
            </Container>
          </ErrorBoundary>
        </Body>
      </PageContainer>
    </>
  );
};
export default Reviews;
