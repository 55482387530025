import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import TextareaAutosize from 'react-textarea-autosize';
import { useIntl } from 'react-intl';
import { retrieve, store } from '../../../modules/storage';

const Form = styled.form`
  width: 100%;
  display: flex;
`;

const SendInput = styled.div`
  display: flex;
  align-items: flex-end;
  flex: 1;
`;

const SendInputContainer = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  padding: 5px 10px;
  min-height: 32px;
`;

const Input = styled(TextareaAutosize)`
  flex: 1;
  border: none;
  background: transparent;
  outline: none;
  resize: none;
`;

const StyledButton = styled(Button)<{ $isSmallChat: boolean }>`
  height: 32px;
  padding: 0 !important;
  background: #006876 !important;
  border-radius: 16px !important;
  ${(props) =>
    props.$isSmallChat &&
    `
      min-width: 40px !important;
      padding: 6px 10px !important;
  `}
`;

const SendMessage = ({ handleMessageSend, isSmallChat = false }) => {
  const [inboxMessage, setInboxMessage] = useState('');
  const [cursor, setCursor] = useState(retrieve.number('messageInputCursorPosition', 0));

  const intl = useIntl();
  const sendMessage = (e) => {
    e.preventDefault();
    setInboxMessage('');
    handleMessageSend(inboxMessage);
  };
  const handleSendInput = useCallback((e) => {
    const value = e.target.value;
    setInboxMessage(value.slice(0, 500));
  }, []);
  const handleKeyDown = (event) => {
    setCursor(event.target.selectionStart);
    store.number('messageInputCursorPosition', event.target.selectionStart);
    if (event.keyCode === 13 && (event.metaKey || event.ctrlKey || event.altKey)) {
      sendMessage(event);
    }
  };
  return (
    <Form autoComplete="off" onSubmit={sendMessage}>
      <SendInput>
        <SendInputContainer>
          <Input
            placeholder={intl.formatMessage({
              id: 'messages.sendMessage.input',
              defaultMessage: 'messages.sendMessage.input',
            })}
            value={inboxMessage}
            maxRows={16}
            maxLength={500}
            onChange={handleSendInput}
            onKeyDown={(e) => handleKeyDown(e)}
            onFocus={(e) => {
              e.target.setSelectionRange(cursor, cursor);
            }}
          />
        </SendInputContainer>
        <StyledButton $isSmallChat={isSmallChat} variant="contained" type="submit">
          <SendIcon fontSize="small" />
        </StyledButton>
      </SendInput>
    </Form>
  );
};

export default React.memo(SendMessage);
